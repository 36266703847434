/* eslint-disable */
import { useMemo, useContext, useEffect, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { AuthContext } from 'src/auth/context/firebase/auth-context';
import { subDays, format } from 'date-fns';
import { ref, get } from 'firebase/database';
import { doc, getDoc } from 'firebase/firestore';
import { rtdb, DB } from 'src/auth/context/firebase/auth-provider';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import EmployeeReport from './download-report';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  accounts: icon('ic_accounts'),
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  markerID: icon('ic_markerID'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  routes: icon('ic_routes'),
  pipeline: icon('ic_pipeline'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_map'),
  report: icon('ic_report'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { user } = useContext(AuthContext);
  const [pokaziAccount, setPokaziAccount] = useState(false);

  useEffect(() => {
    console.log('user v confgi-navigation ', user.enterprise);
    setPokaziAccount(Boolean(user.enterprise || user.subAdmin)); // Simplifying the setting logic
    console.log(pokaziAccount);
  }, [user.enterprise, user.subAdmin]); // Adding dependency to useEffect to watch user.enterprise

  const handleDownloadReport = async (reportType) => {
    const daysToSubtract = reportType === '7-Day' ? 7 : 30;
    const endDate = new Date();
    const startDate = subDays(endDate, daysToSubtract);

    let notes = [];
    let employeeDisplayName = '';
    let employeeEmail = '';

    const employeeUid = user?.uid;

    if (!employeeUid) {
      console.error('No user UID available');
      return;
    }

    // Fetch employee notes from notes/ path
    const notesRef = ref(rtdb, `notes/${employeeUid}`);
    const snapshot = await get(notesRef);
    if (snapshot.exists()) {
      const notesData = snapshot.val();
      console.log(notesData);

      // Convert notes object to array and filter by date range
      let recentNotes = Object.entries(notesData)
        .map(([noteId, note]) => {
          if (!note.date) {
            console.warn(`Note ${noteId} skipped: date is undefined`, note);
            return null;
          }
          const noteDate = new Date(note.date); // Use timestamp directly
          if (isNaN(noteDate.getTime())) {
            console.warn(`Note ${noteId} skipped: invalid date "${note.date}"`, note);
            return null;
          }
          return {
            ...note,
            dateObj: noteDate,
            date: format(noteDate, 'dd/MM/yyyy'),
            noteId,
          };
        })
        .filter((note) => note !== null && note.dateObj >= startDate && note.dateObj <= endDate);

      // Sort notes by date and then placeName
      notes = recentNotes.sort(
        (a, b) => a.dateObj - b.dateObj || a.placeName.localeCompare(b.placeName)
      );
    }

    // Fetch user info
    const userRef = doc(DB, 'users', employeeUid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      employeeDisplayName = userSnap.data().displayName || 'No Name';
      employeeEmail = userSnap.data().email || 'No Email';
    }

    // Generate and download the PDF report
    const blob = await pdf(
      <EmployeeReport
        displayName={user.displayName}
        email={user.email}
        notes={notes}
        employeeDisplayName={employeeDisplayName}
        employeeEmail={employeeEmail}
        fromDate={startDate}
        toDate={endDate}
        reportType={reportType}
      />
    ).toBlob();

    const formattedDate = format(endDate, 'dd-MM-yyyy');
    saveAs(blob, `${reportType}-Activity-Report-${formattedDate}.pdf`); // No employeeDisplayName

  };

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          { title: t('Map'), path: paths.dashboard.root, icon: ICONS.dashboard },
          // { title: t('ecommerce'), path: paths.dashboard.general.ecommerce, icon: ICONS.ecommerce },
          // { title: t('booking'), path: paths.dashboard.general.booking, icon: ICONS.booking },
          // { title: t('file'), path: paths.dashboard.general.file, icon: ICONS.file },
          // CALENDAR
          {
            title: t('calendar'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
          },
          // {
          //   title: t('Routes'),
          //   path: paths.dashboard.routes,
          //   icon: ICONS.routes,
          //   condition: user.employee,
          // },
          {
            title: t('Activity Reports'),
            path: '#',
            icon: ICONS.report,
            condition: !pokaziAccount,
            children: [
              {
                title: t('7-Day Report'),
                path: '#',
                onClick: () => handleDownloadReport('7-Day'),
                condition: !pokaziAccount,
              },
              {
                title: t('30-Day Report'),
                path: '#',
                onClick: () => handleDownloadReport('30-Day'),
                condition: !pokaziAccount,
              },
            ],
          },
          {
            title: t('Referral'),
            path: paths.dashboard.general.banking,
            // path: '#',
            icon: ICONS.banking,
            // disabled: true,
            info: (
              <Label color="info" startIcon={<Iconify icon="tdesign:money" />}>
                Earn rewards
              </Label>
            ),
          },
        ].filter((item) => item.condition === undefined || item.condition === true),
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          // USER
          {
            title: t('user'),
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            // children: [
            //   { title: t('profile'), path: paths.dashboard.user.root },
            //   { title: t('cards'), path: paths.dashboard.user.cards },
            //   { title: t('list'), path: paths.dashboard.user.list },
            //   { title: t('create'), path: paths.dashboard.user.new },
            //   { title: t('edit'), path: paths.dashboard.user.demo.edit },
            //   { title: t('account'), path: paths.dashboard.user.account },
            // ],
          },
          {
            title: t('Accounts'),
            path: paths.dashboard.user.new,
            icon: ICONS.accounts,
            condition: pokaziAccount,
          },
          { title: t('analytics'), path: paths.dashboard.general.analytics, icon: ICONS.analytics, condition: pokaziAccount, },
          {
            title: t('Marker ID'),
            path: paths.dashboard.product.root,
            icon: ICONS.markerID,
            // condition: pokaziAccount,
          },
          {
            title: t('Pipeline'),
            path: paths.dashboard.kanban,
            icon: ICONS.pipeline,
            condition: !user.employee && !user.subAdmin,
          },

          // PRODUCT
          // {
          //   title: t('product'),
          //   path: paths.dashboard.product.root,
          //   icon: ICONS.product,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.product.root },
          //     { title: t('details'), path: paths.dashboard.product.demo.details },
          //     { title: t('create'), path: paths.dashboard.product.new },
          //     { title: t('edit'), path: paths.dashboard.product.demo.edit },
          //   ],
          // },

          // ORDER
          // {
          //   title: t('order'),
          //   path: paths.dashboard.order.root,
          //   icon: ICONS.order,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.order.root },
          //     { title: t('details'), path: paths.dashboard.order.demo.details },
          //   ],
          // },

          // INVOICE
          // {
          //   title: t('invoice'),
          //   path: paths.dashboard.invoice.root,
          //   icon: ICONS.invoice,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.invoice.root },
          //     { title: t('details'), path: paths.dashboard.invoice.demo.details },
          //     { title: t('create'), path: paths.dashboard.invoice.new },
          //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
          //   ],
          // },

          // BLOG
          // {
          //   title: t('blog'),
          //   path: paths.dashboard.post.root,
          //   icon: ICONS.blog,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.post.root },
          //     { title: t('details'), path: paths.dashboard.post.demo.details },
          //     { title: t('create'), path: paths.dashboard.post.new },
          //     { title: t('edit'), path: paths.dashboard.post.demo.edit },
          //   ],
          // },

          // JOB
          // {
          //   title: t('job'),
          //   path: paths.dashboard.job.root,
          //   icon: ICONS.job,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.job.root },
          //     { title: t('details'), path: paths.dashboard.job.demo.details },
          //     { title: t('create'), path: paths.dashboard.job.new },
          //     { title: t('edit'), path: paths.dashboard.job.demo.edit },
          //   ],
          // },

          // TOUR
          // {
          //   title: t('tour'),
          //   path: paths.dashboard.tour.root,
          //   icon: ICONS.tour,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.tour.root },
          //     { title: t('details'), path: paths.dashboard.tour.demo.details },
          //     { title: t('create'), path: paths.dashboard.tour.new },
          //     { title: t('edit'), path: paths.dashboard.tour.demo.edit },
          //   ],
          // },

          // FILE MANAGER
          // {
          //   title: t('file_manager'),
          //   path: paths.dashboard.fileManager,
          //   icon: ICONS.folder,
          // },

          // MAIL
          // {
          //   title: t('mail'),
          //   path: paths.dashboard.mail,
          //   icon: ICONS.mail,
          //   info: <Label color="error">+32</Label>,
          // },

          // CHAT
          // {
          //   title: t('chat'),
          //   path: paths.dashboard.chat,
          //   icon: ICONS.chat,
          // },

          // KANBAN
          // {
          //   title: t('kanban'),
          //   path: paths.dashboard.kanban,
          //   icon: ICONS.kanban,
          // },
        ].filter((item) => item.condition === undefined || item.condition === true),
      },

      // DEMO MENU STATES
      // {
      //   subheader: t(t('other_cases')),
      //   items: [
      //     {
      //       // default roles : All roles can see this entry.
      //       // roles: ['user'] Only users can see this item.
      //       // roles: ['admin'] Only admin can see this item.
      //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
      //       // Reference from 'src/guards/RoleBasedGuard'.
      //       title: t('item_by_roles'),
      //       path: paths.dashboard.permission,
      //       icon: ICONS.lock,
      //       roles: ['admin', 'manager'],
      //       caption: t('only_admin_can_see_this_item'),
      //     },
      //     {
      //       title: t('menu_level'),
      //       path: '#/dashboard/menu_level',
      //       icon: ICONS.menuItem,
      //       children: [
      //         {
      //           title: t('menu_level_1a'),
      //           path: '#/dashboard/menu_level/menu_level_1a',
      //         },
      //         {
      //           title: t('menu_level_1b'),
      //           path: '#/dashboard/menu_level/menu_level_1b',
      //           children: [
      //             {
      //               title: t('menu_level_2a'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
      //             },
      //             {
      //               title: t('menu_level_2b'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
      //               children: [
      //                 {
      //                   title: t('menu_level_3a'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
      //                 },
      //                 {
      //                   title: t('menu_level_3b'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       title: t('item_disabled'),
      //       path: '#disabled',
      //       icon: ICONS.disabled,
      //       disabled: true,
      //     },
      //     {
      //       title: t('item_label'),
      //       path: '#label',
      //       icon: ICONS.label,
      // info: (
      //   <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
      //     NEW
      //   </Label>
      // ),
      //     },
      //     {
      //       title: t('item_caption'),
      //       path: '#caption',
      //       icon: ICONS.menuItem,
      //       caption:
      //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
      //     },
      //     {
      //       title: t('item_external_link'),
      //       path: 'https://www.google.com/',
      //       icon: ICONS.external,
      //     },
      //     {
      //       title: t('blank'),
      //       path: paths.dashboard.blank,
      //       icon: ICONS.blank,
      //     },
      //   ],
      // },
    ],
    [t, pokaziAccount]
  );

  return data;
}
