/* eslint-disable */
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useLocation } from 'react-router-dom';
// components
import Logo from 'src/components/logo';
import { RouterLink } from 'src/routes/components';
//
import { HEADER } from '../config-layout';
import HeaderShadow from './header-shadow';
import SettingsButton from './settings-button';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useContext } from 'react';
import { AuthContext } from 'src/auth/context/firebase/auth-context';
// ----------------------------------------------------------------------

export default function HeaderSimple() {
  const theme = useTheme();
  const { userCurrency, updateCurrency } = useContext(AuthContext);
  const popover = usePopover();
  const location = useLocation();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const handleCurrencyChange = (newCurrency) => {
    updateCurrency(newCurrency); // Update via context
    popover.onClose();
  };

  const isPricingPage = location.pathname === '/pricing';

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Logo />

        {isPricingPage && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {/* Currency selection button with popover */}
          <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1 }}>
            <Button
              onClick={popover.onOpen}
              startIcon={
                <Iconify
                  width={18}
                  icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ ml: 1 }}
                />
              }
              sx={{
                typography: 'subtitle1',
                // bgcolor: 'background.neutral',
                borderRadius: 1,
              }}
            >
              <Typography variant="inherit" noWrap>
              {userCurrency ? `${userCurrency} (${userCurrency === 'EUR' ? '€' : userCurrency === 'USD' ? '$' : '£'})` : 'Select Currency'}             
              </Typography>
            </Button>
          </Stack>

          {/* Popover with currency options */}
          <CustomPopover
            open={popover.open}
            onClose={popover.onClose}
            arrow="top-left"
            sx={{ width: 140 }}
          >
            <MenuItem
              selected={userCurrency === 'EUR'}
              onClick={() => handleCurrencyChange('EUR')}
            >
              <Iconify icon="mdi:currency-eur" sx={{ mr: 1 }} />
              EUR (€)
            </MenuItem>
            <MenuItem
              selected={userCurrency === 'USD'}
              onClick={() => handleCurrencyChange('USD')}
            >
              <Iconify icon="mdi:currency-usd" sx={{ mr: 1 }} />
              USD ($)
            </MenuItem>
            <MenuItem
                selected={userCurrency === 'GBP'}
                onClick={() => handleCurrencyChange('GBP')}
              >
                <Iconify icon="mdi:currency-gbp" sx={{ mr: 1 }} />
                GBP (£)
              </MenuItem>
          </CustomPopover>
        </Box>
        )}

        {/* <Stack direction="row" alignItems="center" spacing={1}>
          <SettingsButton />

          <Link
            href={paths.faqs}
            component={RouterLink}
            color="inherit"
            sx={{ typography: 'subtitle2' }}
          >
            Need help?
          </Link>
        </Stack> */}
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
